import { render, staticRenderFns } from "./TransDetailMemo.vue?vue&type=template&id=45fb9892&scoped=true&"
import script from "./TransDetailMemo.vue?vue&type=script&lang=js&"
export * from "./TransDetailMemo.vue?vue&type=script&lang=js&"
import style0 from "./TransDetailMemo.vue?vue&type=style&index=0&id=45fb9892&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45fb9892",
  null
  
)

export default component.exports