<template>
  <div :class="[ 'textarea_comm', { disabled: isDisabled, textarea_error: isError, textarea_count: isCount } ]" :style="cssProps">
    <p v-if="isError && errorDesc" class="desc_error">{{ errorDesc }}</p>
    <textarea 
      v-model="input" 
      :placeholder="placeholder.replaceAll('<br>',` `)"
      :maxlength="max"
      :disabled="isDisabled"
      ref="textarea" />
    <p
    class="desc_textarea"
    v-if="isCount"><em :class="{ on : countingValue != 0 }">{{ countingValue }}</em> &#47; {{ max }}</p>
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    value: String,
    max: {
      type: Number,
      default: 500
    },
    placeholder: String,
    showLine:{
      type: Number,
      default: 4
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    errorDesc: String,
    isCount: {
      type: Boolean,
      default: false
    },
    isFocusOnMount: {
      type: Boolean,
      default: false
    },
    isFocus: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    },
    countingValue() {
      return this.value.length;
    },
    cssProps(){
      var obj = {};
      obj = {
        // '--minHeight': `${(this.showLine*24)+28}px`,
        '--minHeight': `120px`,
      }
      return obj;
    }
  },
  watch:{
    isFocus(){
      if(this.isFocus){
        this.$refs.textarea.focus();
      }
    }
  },
  mounted() {
    if( this.isFocusOnMount ) {
      this.$refs.textarea.focus();
    }
  },
}
</script>

<style scoped>
.selectbox_comm + .textarea_comm{margin-top:8px}
.textarea_comm{overflow:hidden;}
.textarea_comm textarea{width:100%;height:var(--minHeight);padding:14px 16px;border:0 none;border-radius:4px;font-weight:600;font-size:16px;line-height:24px;background-color:#F3F1F1;color:#111;outline:none;box-shadow:none;box-sizing:border-box;resize:none;vertical-align:top;caret-color:#8F69DD}
/* placeholder */
.textarea_comm textarea::-webkit-input-placeholder{font-weight:500;color:#999}
.textarea_comm textarea:-moz-placeholder{font-weight:500;color:#999}
.textarea_comm textarea::-moz-placeholder{font-weight:500;color:#999}
.textarea_comm textarea:-ms-input-placeholder{font-weight:500;color:#999}
/* focuse */
/* .textarea_comm:focus-within textarea{border-color:$PRIMARY_COLOR} */
.textarea_comm:focus-within textarea{background-color:#F0EAFA}

/* disabled */
.textarea_comm.disabled:hover,
.textarea_comm.disabled:hover textarea{cursor:no-drop}
.textarea_comm.disabled textarea,
.textarea_comm textarea:disabled{background-color:#F3F1F1;color:#999}
.textarea_comm.disabled .desc_textarea{opacity:.5}
/* count */
/* .textarea_count{padding-bottom:22px} */
.desc_textarea{float:right;padding:6px 0 0;font-weight:500;font-size:12px;line-height:16px;color:#999;text-align:right}
.desc_textarea em{font-weight:500;font-size:12px;line-height:16px}
.desc_textarea em.on{font-weight:700;color:#999}


/* error */
.textarea_comm.textarea_error textarea{color:#E23C3C}
/* error placeholder */
.textarea_comm.textarea_error textarea::-webkit-input-placeholder{color:#E23C3C}
.textarea_comm.textarea_error textarea:-moz-placeholder{color:#E23C3C}
.textarea_comm.textarea_error textarea::-moz-placeholder{color:#E23C3C}
.textarea_comm.textarea_error textarea:-ms-input-placeholder{color:#E23C3C}
/* error focus */
/* .textarea_comm.textarea_error:focus-within textarea{border:1px solid $PRIMARY_COLOR} */
/* error focus placeholder */
.textarea_comm.textarea_error:focus-within textarea::-webkit-input-placeholder{font-weight:500;color:#999}
.textarea_comm.textarea_error:focus-within textarea:-moz-placeholder{font-weight:500;color:#999}
.textarea_comm.textarea_error:focus-within textarea::-moz-placeholder{font-weight:500;color:#999}
.textarea_comm.textarea_error:focus-within textarea:-ms-input-placeholder{font-weight:500;color:#999}
/* error desc */
/* .desc_error{padding:0 0 6px;font-weight:500;font-size:14px;line-height:24px;color:$ERROR_COLOR}
.desc_error .icon_emoji{display:inline-block;margin-right:8px;vertical-align:top} */
</style>
