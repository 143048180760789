<template>
  <PageWithLayout
    :isBodyFull="true"
    :topPadding="16"
    :bottomPadding="104"
    :isBackBtn="true"
    backRouteName="TransDetailV2"
    :backRouteQuery="$route.query"
    :backPage="$route.query.backPage">
    <strong class="tit_memo" v-html="$poData.trans_details_memo_direction"></strong>
    <Textarea
      :placeholder="$poData.trans_details_memo_placeholder"
      :value.sync="viewModel.detailData.memo"
      :max="30"
      @update:value="value => viewModel.onUpdateMemo(value)"
      :style="{ '--fullHeight' : `${textareaFullHeight}px` }"/>
    <div class="area_fixed_bottom">
      <p class="desc_count" v-if="viewModel.detailData.memo">메모 {{viewModel.detailData.memo.length}}/30자</p>
      <Button
        btnStyle="primary"
        :disabled="!viewModel.memoChanged"
        :text="$poData.trans_details_memo_main_btn"
        @onClickBtn="viewModel.putTransDetailMemo()"/>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import Textarea from '@/components/common/textarea/Textarea';
import Button from '@/components/common/button/Button';

import { mapGetters } from 'vuex';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
// viewModel
import TransDetailMemoViewModel from '@/views/v2/transaction/viewModel/TransDetailMemoViewModel'

export default {
  name: 'TransDetailMemo',
  mixins:[ InterFaceMixin ],
  components:{
    PageWithLayout,
    Textarea,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    const query = this.$route.query;
    this.viewModel.init(id,query);
  },
  computed: {
    ...mapGetters('commonViewport', [ 'getWindowHeight' ]),
    textareaFullHeight(){
      return this.getWindowHeight - 54 - 16 - 104 - 46
    }
  },
  data(){
    return{
      viewModel: new TransDetailMemoViewModel(),
    }
  },
}
</script>

<style scoped>
.tit_memo{display:block;padding:0 20px 16px;font-weight:700;font-size:24px;line-height:30px;color:#000}
.textarea_comm >>> textarea{height:var(--fullHeight);padding:20px}
.textarea_comm >>> textarea,
.textarea_comm:focus-within >>> textarea{background:none}

.area_fixed_bottom{position:absolute;bottom:0;left:0;right:0;padding:0}
.area_fixed_bottom .desc_count{margin:0 20px 12px;font-size:14px;line-height:22px;color:#999}
</style>